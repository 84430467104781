import $ from 'jquery';
import 'what-input';
import { Swiper, Navigation, Pagination, Scrollbar } from 'swiper';

import Inputmask from 'inputmask';
// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';

let selector = document.getElementById('input-mask');

if (selector) {
  Inputmask('9-999-999-99-99').mask(selector);
}

$(document).foundation();

$(function () {
  let heightMenu;

  const WIDTH_TABLET = 1199;

  $('.js-header__burger').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('header__burger_active');
    $('.main-menu').toggleClass('main-menu_active');

    if (window.innerWidth < WIDTH_TABLET) {
      $('body').toggleClass('body-hide');
      $('html').toggleClass('active');
      if ($('body').hasClass('body-hide')) {
        let hHeader = $('.header').outerHeight(true);
        $('body').css('height', '100vh');
        $('main').css('overflow-x', 'initial');
      } else {
        $('body, .main-menu').css('height', '');
        $('main').css('overflow-x', 'hidden');
      }
    }
  });

  $('.js-take-order').on('click', function (e) {
    e.preventDefault();
    $(this).toggleClass('js-take-order_active');
    $('.messengers-header').toggleClass('messengers-header_active');
    //меняем текст
    if ($(this).hasClass('js-take-order_active')) {
      let textBtn = $(this).data('close');
      $(this).find('.secondary-btn__inner-wrap').text(textBtn);
    } else {
      let textBtn = $(this).data('open');
      $(this).find('.secondary-btn__inner-wrap').text(textBtn);
    }
  });

  $(window).resize(function () {
    if (window.innerWidth > WIDTH_TABLET) {
      $('body').removeClass('body-hide');
      $('html').removeClass('active');
      $('body').css('height', '');
      $('main').css('overflow-x', 'hidden');
    }

    //подставляем bg к top-block
    bgTopBlock();
  });

  //подставляем bg к top-block
  function bgTopBlock() {
    if ($('.top-block').length > 0) {
      if (window.innerWidth > WIDTH_TABLET) {
        let imgUrl = $('.top-block').data('url-desc');
        $('.top-block').css('background-image', `url(${imgUrl})`);
      } else {
        let imgUrl = $('.top-block').data('url-mob');
        $('.top-block').css('background-image', `url(${imgUrl})`);
      }
    }
  }

  bgTopBlock();

  //sale-slider
  Swiper.use([Navigation, Pagination, Scrollbar]);
  if ($('.sale-slider').length > 0) {
    let swiper = new Swiper('.sale-slider .swiper', {
      slidesPerView: 1,
      speed: 500,
      navigation: {
        nextEl: '.sale-slider .swiper-button-next',
        prevEl: '.sale-slider .swiper-button-prev',
      },
      pagination: {
        el: '.sale-slider .swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

  if ($('.example-work').length > 0) {
    let swiper2 = new Swiper('.example-work .swiper', {
      slidesPerView: 3,
      speed: 500,
      navigation: {
        nextEl: '.example-work .swiper-button-next',
        prevEl: '.example-work .swiper-button-prev',
      },
      scrollbar: {
        el: '.example-work .swiper-scrollbar',
        draggable: true,
      },
      breakpoints: {
        320: {
          spaceBetween: 30,
          slidesPerView: 'auto',
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 'auto',
        },
        1100: {
          slidesPerView: 3,
          spaceBetween: 60,
        },
      },
      on: {
        init: function (swiper) {
          $('.swiper-arrows__count').text(
            `${swiper.activeIndex + 1}/${swiper.slides.length}`
          );
        },

        slideChange: function (swiper) {
          const countResult =
            swiper.isEnd == true
              ? `${swiper.slides.length}/${swiper.slides.length}`
              : `${swiper.activeIndex + 1}/${swiper.slides.length}`;

          $('.swiper-arrows__count').text(countResult);
        },
      },
    });
  }

  // init windows swiper only below tablet
  let swiper = undefined;
  let init = false;

  const initWindowTypesSwiper = () => {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 1247px)');
    let desktop = window.matchMedia('(min-width: 1260px)');

    if (!init) {
      init = true;
      swiper = new Swiper('.window-types .swiper', {
        slidesPerView: 'auto',
        speed: 500,
        navigation: {
          nextEl: '.window-types .swiper-button-next',
          prevEl: '.window-types .swiper-button-prev',
        },
        scrollbar: {
          el: '.window-types .swiper-scrollbar',
          draggable: true,
        },
        breakpoints: {
          320: {
            spaceBetween: 20,
            slidesPerView: 'auto',
          },
          768: {
            spaceBetween: 20,
            slidesPerView: 'auto',
          },
        },
      });
    } else if (desktop.matches && init) {
      swiper.destroy();
      init = false;
    }
  };

  if ($('.window-types').length > 0) {
    initWindowTypesSwiper();
  }
  window.addEventListener('resize', function () {
    if ($('.window-types').length > 0) {
      initWindowTypesSwiper();
    }
  });

  //spoiler
  $('.js-spoiler__btn').on('click', function (e) {
    e.preventDefault();
    $(this).parents('.spoiler-block').addClass('spoiler-block_active');
  });

  // swap example-work img
  $('.js-change-example-work').on('click', function (e) {
    e.preventDefault();
    if (!$(this).hasClass('example-work__text-changer_active')) {
      let imgSrc = $(this).data('img');
      $(this)
        .parents('.example-work__item')
        .find('.example-work__item-img')
        .attr('src', imgSrc);
      $(this)
        .parents('.example-work__changer')
        .find('.example-work__text-changer')
        .toggleClass('example-work__text-changer_active');
    }
  });

  // desktop menu
  $('.main-menu__wrap .main-menu__li').hover(
    function (e) {
      if ($(this).find('.main-menu__tab-menu-wrap').length > 0) {
        $(this)
          .find('.main-menu__tab-menu-wrap')
          .addClass('main-menu__tab-menu-wrap_active');
      }
    },
    function (e) {
      if ($(this).find('.main-menu__tab-menu-wrap').length > 0) {
        $(this)
          .find('.main-menu__tab-menu-wrap')
          .removeClass('main-menu__tab-menu-wrap_active');
      }
    }
  );

  $('.main-menu__tabs-link').on('click', function (e) {
    e.preventDefault();
    const parent = $(this).parents('.main-menu__tabs-title');
    let indexEl = parent.index();
    $(this)
      .parents('.main-menu__tabs')
      .find('.main-menu__tabs-title')
      .removeClass('main-menu__tabs-title_active');
    parent.addClass('main-menu__tabs-title_active');
    $(this)
      .parents('.main-menu__tab-menu-wrap')
      .find('.tabs-panel')
      .removeClass('is-active');
    $(this)
      .parents('.main-menu__tab-menu-wrap')
      .find('.tabs-panel')
      .eq(indexEl)
      .addClass('is-active');
  });



  $('body').on('submit', '.js-send-telegram', function (event) {
    event.preventDefault();
    let nameForm = $(this).find('[name=name-form]').val();
    let telForm = $(this).find('[name=tel-form]').val();
    const currentRecaptchaID = event.target.querySelector('.g-recaptcha').id.split("-").join("");
    const response = grecaptcha.getResponse(window[currentRecaptchaID]);
    grecaptcha.reset(window[currentRecaptchaID]);

    if(response.length == 0) {
      alert('Вы не прошли проверку CAPTCHA должным образом');
      return false;
    }

    $.ajax({
      url: '/ajax/send_form_telegram.php',
      type: 'POST',
      dataType: 'json',
      data: { 'diagnostic-name': nameForm, 'diagnostic-phone': telForm },
      success: function (result) {
        if (result.status) {
          history.pushState({}, '', '?spasibo');
          $('#success-modal').foundation('open');
          $('#success-modal').find('.modal-form__title').text(result.message);
        }
      },
      error: function (jqXHR, exception) {
        console.log(jqXHR.responseText);
      },
    });
  });

  // video section
  const videoSection = document.querySelector('.js-video-section');
  if (videoSection) {
    const playButton = videoSection.querySelector('.js-play-video-btn');

    playButton.addEventListener('click', function (evt) {
      evt.preventDefault();
      const video = videoSection.querySelector('.js-video');
      const videoBg = videoSection.querySelector('.js-video-bg');
      video.classList.add('show');
      videoBg.classList.add('hide');
      video.play();
    });
  }
});
